// $(function() {
//   $.scrollUp({
//     scrollText: '',
//     scrollSpeed: 500
//   });
// });

const scrollDuration = 700;

$(function() {
  // 导航sticky
  $('.navbar-default').sticky({
    topSpacing: 0,
    zIndex: 1000
  });

  // 导航菜单
  $('#menu li.nav-item').click(function(e) {
    const offset = -parseInt($('#fullpage').css('marginTop'));
    const navanchor = $(e.currentTarget).data('navanchor');
    $('html').scrollTo($('#' + navanchor), scrollDuration, {offset: offset});
    $('#main-navbar-collapse').collapse('hide');
  });

  // 产品服务-导航菜单
  $('ul.nav-main a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    // 删除旧的选中样式
    const thiz = $(this);
    $('ul.nav-sub a[data-toggle="tab"]').each(function(index) {
      if($(this) !== thiz) {
        $(this).parent().removeClass('active');
      }
    });
    const active = thiz.parent().hasClass('active');
    if(active) {
      const tabs = {
        '计算机视觉': '2D矩形',
        '自动驾驶': '点云标注',
        '语音文本': '语音标注',
        '工具定制': '标注工具',
      };
      const ariaControls = thiz.attr('aria-controls');
      const targetTab = $('#' + ariaControls + ' a[aria-controls="' + tabs[ariaControls] + '"]');
      targetTab.tab('show');
    }
  });

  $('.wechat-item-icon').tooltipster({
    // contentCloning: true,
    interactive: true,
  });

  // window窗口大小改变时，收起已展开的导航
  window.onresize = function(e) {
    $('#main-navbar-collapse').collapse('hide');
    if(e.target.innerWidth <= 768){
      $('.swiper-button-prev').addClass('swiper-button-hidden');
      $('.swiper-button-next').addClass('swiper-button-hidden');
    } else {
      $('.swiper-button-prev').removeClass('swiper-button-hidden');
      $('.swiper-button-next').removeClass('swiper-button-hidden');
    }
  };

  const uaParser = new UAParser();
  const UAResult = uaParser.getResult();

  if(window.innerWidth < 768 || (UAResult && UAResult.device && UAResult.device.type === 'mobile')) {
    $('.swiper-button-prev').addClass('swiper-button-hidden');
    $('.swiper-button-next').addClass('swiper-button-hidden');
  }

  const swiper = new Swiper('.swiper', {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      hiddenClass: 'swiper-button-hidden'
    },
    pagination: {
      el: ".swiper-pagination",
      type: 'custom',
      clickable: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    // slidesPerGroup: 3,
    loopFillGroupWithBlank: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    },
    slidesPerView: 3,
    spaceBetween: 30,
  });

  const appParser = new UAParser((function()  {
        const MODEL     = 'model';
        const NAME      = 'name';
        const TYPE      = 'type';
        const VENDOR    = 'vendor';
        const VERSION   = 'version';
        const MOBILE    = 'mobile';
        const TABLET    = 'tablet';

        const Apps = Object.freeze({
          browser : [
            [/MicroMessenger\/([\d\.]+)/i], [VERSION, [NAME, 'Wechat'], [TYPE, 'app']],
            [/QQ\/([\d\.]+)/i], [VERSION, [NAME, 'QQ'], [TYPE, 'app']],
            [/WeiBo(?:(?:__)?\/?)?([\d.]+)/i], [VERSION, [NAME, 'WeiBo'], [TYPE, 'app']],
            [/UCBrowser\/([\d.]+)/i], [VERSION, [NAME, 'UC'], [TYPE, 'app']],
            [/AliApp\(AP\/([\d.]+)\)/i], [VERSION, [NAME, 'Alipay'], [TYPE, 'app']],
            [/AliApp\(TB\/([\d.]+)\)/i], [VERSION, [NAME, 'TaoBao'], [TYPE, 'app']],
            [/AliApp\(DingTalk\/([\d.]+)\)/i], [VERSION, [NAME, 'DingTalk'], [TYPE, 'app']],
            [/baiduboxapp\/([\d.]+)/i], [VERSION, [NAME, 'BaiduBoxApp'], [TYPE, 'app']],
          ]
        });

        return {
          Apps
        }
      }
  )().Apps);
  const APPResult = appParser.getResult();

  if ((APPResult && APPResult.browser && APPResult.browser.name) !== 'BaiduBoxApp') {
    $('[data-link]').each(function() {
      const link = $(this).data('link');
      const target = $(this).data('target');
      const text = $(this).text();
      $(this).html($('<a class="a-link" target="' + target +'" href="' + link + '">' + text + '</a>'));
    })
  }
});
